import React, { forwardRef, useImperativeHandle, useState } from "react";
/* eslint-disable */
import {
  Flex,
  // Text,
  // Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage
} from "@chakra-ui/react";
// import { Select, Space } from 'antd';
// import type { SelectProps } from 'antd';


import { Field, Form, Formik } from 'formik';

import { getMethod, postMethod } from 'api/index';
import { useSelector } from 'react-redux';

const DevelopmentDialog = forwardRef((props, ref) => {
  const { onRefresh } = props;
  const [visiable, setVisiable] = useState(false);
  // const cancelRef = React.useRef();
  const [rowData, setData] = useState({})
  const userInfo = useSelector((state) => state.userCmsInfos)

  const [planList, setPlanList] = useState([])
  useImperativeHandle(ref, () => ({
    setRow: (record) => {
      setData(record)
      setVisiable(true);
      getPlanList();
    }
  }))

  const getPlanList = () => {
    getMethod('/campaign/canvass/serviceplan/query').then((res) => {
      if (res && res.code === 200) {
        let data = res.data.map((item) => {
          return {
            // value: String(item.id),
            // label: item.name
            id: String(item.id),
            service_name: item.name
          }
        })
        setPlanList(data || [])
      }
    })
  }

  // 关闭弹框
  const onClose = () => {
    setVisiable(false);
  }
  const validateName = (value) => {
    let error
    if (!value) {
      error = 'Account name is required'
    }
    return error
  }
  const validatePlan = (value) => {
    let error
    if (!value) {
      error = 'Account plan is required'
    }
    return error
  }
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = 'Email is required'
    }
    return error
  }
  const validateExpiration = (value) => {
    let error
    if (!value) {
      error = 'Expiration date is required'
    }
    return error
  }
  const validateSmsCount = (value) => {
    let error
    if (value !== 0 && !value) {
      error = 'Count of free monthly SMS remaining is required'
    } else if (!(/^[0-9]+$/.test(value))) {
      error = 'Please enter a number'
    } else if (value < 0) {
      error = 'Please enter a number greater than or equal to 0.'
    }
    return error
  }

  const handleSelectChange = (e, form) => {
    form.setFieldValue('plan', e)
    planList.forEach((item) => {
      if (item.id === e.target.value) {
        form.setFieldValue('plan', e.target.value)
        // if (!rowData.id) {
        //   form.setFieldValue('sms_count', item.free_sms_count_per_month || 0)
        // }
      }
    })
  }
  return (
    <Modal size='xl' isOpen={visiable} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{rowData.id ? 'Edit' : 'Add'}</ModalHeader>
        <ModalCloseButton _focus={{
          boxShadow: "none",
          border: "none"
        }} />
        <ModalBody>
          <Formik
            initialValues={{ email: rowData.email || '', name: rowData.name || '', plan: rowData.service_plan, expiration_date: rowData.expiration_date || '', sms_count: '' || rowData?.free_sms_count_per_month }}
            onSubmit={(values, actions) => {
              try {
                let params = {}
                console.log(values)
                if (rowData.id) {
                  params = {
                    name: values.name,
                    service_plan: values.plan,
                    id: rowData.id,
                    expiration_date: values.expiration_date,
                    // sms_count: values.sms_count,
                    creator_id: userInfo.uid,
                    email: values.email
                  }
                } else {
                  params = {
                    service_plan: values.plan,
                    expiration_date: values.expiration_date,
                    // sms_count: values.sms_count,
                    creator_id: userInfo.uid,
                    name: values.name,
                    email: values.email
                  }
                }
                console.log(params)
                const url = !rowData.id ? '/campaign/canvass/account/create' : '/campaign/canvass/account/edit'
                postMethod(url, params).then((res) => {
                  if (res && res.code === 200) {
                    setVisiable(false);
                    onRefresh()
                  }
                })
                actions.setSubmitting(false)
              } catch (error) {
                // // console.log(error, '22')
              } finally {
              }

            }}
          >
            {(formProps) => (
              <Form className="sing-in-form">
                <Field name='name' validate={validateName}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.name && form.touched.name}>
                      <FormLabel className="name">Account name</FormLabel>
                      <Input disabled={rowData.id} className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' form="novalidateform" {...field} placeholder='Account name' />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="plan" validate={validatePlan} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.plan && form.touched.plan}>
                      <FormLabel className="name">Account plan</FormLabel>
                      <Select {...field} onChange={(e) => handleSelectChange(e, formProps)} className="input-h48" form="novalidateform" errorBorderColor='#E53E3E' placeholder="please choose Account plan">
                        {
                          planList?.map((item, index) => {
                            return <option key={index} value={item.id}>{item.service_name}</option>
                          })
                        }
                      </Select>
                      {/* <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: '100%',
                        }}
                        placeholder="Please select"
                        // defaultValue={['a10', 'c12']}
                        onChange={(e) => handleSelectChange(e, formProps)}
                        options={planList}
                      /> */}
                      <FormErrorMessage>{form.errors.plan}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='email' validate={validateEmail} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                      <FormLabel className="name">Email</FormLabel>
                      <Input disabled={rowData.id} className="input-h48" type="email" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Email' />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {
                  rowData.id ? <Field name='expiration_date' validate={validateExpiration} mt="10px">
                    {({ field, form }) => (
                      <FormControl isRequired isInvalid={form.errors.expiration_date && form.touched.expiration_date}>
                        <FormLabel className="name">Expiration date</FormLabel>
                        <Input className="input-h48" type="expiration_date" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Expiration date' />
                        <FormErrorMessage>{form.errors.expiration_date}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field> : null
                }
                {/* <Field name='sms_count' validate={validateSmsCount} mt="10px">
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.sms_count && form.touched.sms_count}>
                      <FormLabel className="name">Count of free monthly SMS remaining</FormLabel>
                      <Input className="input-h48" focusBorderColor='#3D5A80' errorBorderColor='#E53E3E' className="input-h48" form="novalidateform" {...field} placeholder='Count of free monthly SMS remaining' />
                      <FormErrorMessage>{form.errors.sms_count}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
                <Flex mt="20px" justifyContent="flex-end" className="sign-operate">
                  <Button
                    type="button"
                    _hover={{
                      background: '#fff',
                      border: '2px solid #5BA4FA'
                    }}
                    background="#fff"
                    color="rgb(40, 58, 83)"
                    border="1px solid rgb(40, 58, 83)"
                    borderRadius="6px"
                    isLoading={props.isSubmitting}
                    w='109px'
                    marginRight="24px"
                    onClick={onClose}
                    fontFamily="Inter"
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="6px"
                    _hover={{
                      background: 'rgb(40, 58, 83);',
                      border: '2px solid #5BA4FA'
                    }}
                    _click={{
                      background: 'rgb(40, 58, 83);',
                    }}
                    background="rgb(40, 58, 83);"
                    color="#fff"
                    isLoading={props.isSubmitting}
                    type='submit'
                    w='109px'
                    fontFamily="Inter"
                  >
                    Submit
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
})

export default DevelopmentDialog;
