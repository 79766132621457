import React from "react";

import {
  Flex,
  Text,
} from "@chakra-ui/react";
export const columns = (props) => {
  const {
    openDialog=() => {},
    deleteDialog=() => {},
    addDialog=() => {},
    truncateDialog=() => {}
  } = props ?? {}

  return [
    {
      title: "Account",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "deleted",
      render (val, item, index) {
        return <span>{val ? 'lock' : 'active'}</span>
      }
    },
    {
      title: "Plan",
      dataIndex: "service_plan_name",
    },
    {
      title: "Create Time",
      dataIndex: "create_time",
    },
    {
      title: "Expiration Time",
      dataIndex: "expiration_date",
      render (val, item, index) {
        return item.isExpiration ? <span>{val}</span> : <span style={{color: 'red'}} className="guoqi">{val}</span>
      }
    },
    {
      title: "Operation",
      render (val, item, index) {
        return <>
          <Flex mw="180px">
            {/* <Text cursor="pointer" onClick={() => detailDialog(item)}  display="inline-block" fontSize='sm' fontWeight='700'>
              Detail
            </Text> */}
            {
              item.deleted ? null :
              <Text cursor="pointer" onClick={() => openDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
                Edit
              </Text>
            }
            <Text cursor="pointer" onClick={() => deleteDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              Delete
            </Text>
            {/* <Text cursor="pointer" onClick={() => addDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              AddVoter
            </Text>
            <Text cursor="pointer" onClick={() => truncateDialog(item)} display="inline-block" ml="8px" fontSize='sm' fontWeight='700'>
              TruncateVoter
            </Text> */}
          </Flex>
        </>
      }
    },
  ];
  
}