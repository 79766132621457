
import React, { useState, useEffect } from "react";
import CustomTable from "components/table";
import Pagination from "components/pagination";
import Card from "components/card/Card";
import Dialog from "./components/add-or-edit";
import DeleteDialog from "./components/delete";
import TruncateDeleteDialog from "./components/truncateDelete";

import DefaultButton from 'components/button'
import VoterPerview from "components/voterPerview";
import { successMessage } from '@utils/message'
import SelectSearch from '@/components/selectSearch/index.jsx'

// import AddDialog from '../../../polymo/polymofund/create-book/components/donor-data-cms'
import {
  columns,
} from "./config";

import { getMethod, postMethod } from 'api/index';
import { useSelector } from 'react-redux';

import {
  Flex,
  Box,
  Input,
  Text,
  Button,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
const queryParams = {
  voter_key_one: '',
  voter_key_two: '',
  voter_value_one: '',
  voter_value_two: ''
}
export default function Settings() {
  const userInfo = useSelector((state) => state.userCmsInfos)
  const [isClickDestribution, setIsClickDestribution] = useState(false)

  const [formQuery, setFormQuery] = useState({ ...queryParams })
  const [voterList] = useState([
    {
      value: 'zip_code',
      label: 'zip code'
    },
    {
      value: 'precinct_abbrv',
      label: 'precinct'
    },
    // {
    //   value: 'ward_abbrv',
    //   label: 'ward'
    // },
    // {
    //   value: 'cong_dist_abbrv',
    //   label: 'cong dist'
    // },
    // {
    //   value: 'county_commiss_abbrv',
    //   label: 'county commiss'
    // },
    {
      value: 'municipality_desc',
      label: 'municipality desc'
    },
    {
      value: 'nc_house_abbrv',
      label: 'nc house'
    },
    {
      value: 'nc_senate_abbrv',
      label: 'nc senate'
    },
    {
      value: 'race_l3',
      label: 'race'
    }
    // {
    //   value: 'super_court_abbrv',
    //   label: 'super court'
    // },
    // {
    //   value: 'judic_dist_abbrv',
    //   label: 'judic dist'
    // },
    // {
    //   value: 'school_dist_abbrv',
    //   label: 'school dist'
    // }
  ])
  const [voterBList, setVoterBList] = useState([])


  // district 右上下拉框
  const [selectListForVal, setSelectList] = useState([])
  // district 右下 下拉框
  const [selectListBForVal, setSelectBListForVal] = useState([])

  const [rowData, setRowData] = useState({})
  // Chakra Color Mode
  const [query, setVal] = React.useState({
    name: '',
    email: ''
  });
  const [total, setTotal] = useState(0)
  /**
   * list 数据
   */
  const [dataList, setData] = React.useState([]);
  const [pagination, setPage] = React.useState({
    currentPage: 1,
    pageSize: 10,
  });

  const [visiable, setVisiable] = useState(false);
  const dialogRef = React.useRef();
  const deleteDialogRef = React.useRef();
  const truncatedeleteDialogRef = React.useRef();

  // const DonorDataDialogRef = React.useRef();

  useEffect(() => {
    getList(pagination, {})
  }, [pagination.currentPage])

  const getList = (pagination, params = {}) => {
    let newParams = {}
    if (Object.keys(params)) {
      for (let i in params) {
        if (params[i]) {
          newParams[i] = params[i]
        }
      }
    }
    postMethod(`/campaign/canvass/account/page/${pagination.currentPage}/${pagination.pageSize}`, newParams, 'campaign').then((res) => {
      if (res && res.code === 200) {
        res.data.list.forEach((item) => {
          item.isExpiration = getDataStr(item.expiration_date)
        })
        setData(res.data.list)
        setTotal(res.data.total_count);
      }
    })
  }
  const handleInputChange = (e, key) => {
    setVal({
      ...query,
      [key]: e.target.value
    })
  }

  const onRefresh = () => {
    getList(pagination, query)
  }

  const queryList = (searchQuery = {}) => {
    getList({
      currentPage: 1,
      pageSize: 10,
    }, query)
  }

  const getDataStr = (n) => {
    return new Date(n).getTime() > new Date().getTime()
  }
  const onSureDelete = (row) => {
    postMethod(`/campaign/canvass/account/upgrade/${row.id}`).then((res) => {
      if (res && res.code === 200) {
        successMessage('success')
        if (dataList.length === 1 && pagination.currentPage > 1) {
          setPage({
            ...pagination,
            currentPage: pagination.currentPage - 1
          })
          getList({
            currentPage: pagination.currentPage - 1,
            pageSize: 10
          }, query)
        } else {
          getList(pagination, query);
        }
        // getList(pagination, query)
      }
    })
  }

  const onSureTruncateDelete = (row) => {
    postMethod(`/campaign/account/truncate/voter/${row.id}`).then((res) => {
      if (res && res.code === 200) {
        successMessage('success')
        if (dataList.length === 1 && pagination.currentPage > 1) {
          setPage({
            ...pagination,
            currentPage: pagination.currentPage - 1
          })
          getList({
            currentPage: pagination.currentPage - 1,
            pageSize: 10
          }, query)
        } else {
          getList(pagination, query);
        }
      }
    })
  }

  /**
   * 全选单选 选中数据
  */
  const emitSelectAll = (list) => {
    // // console.log(list)
  }
  const borderRadius = "8px"

  const onClose = () => {
    setFormQuery({ ...queryParams })
    setIsClickDestribution(false)

    setDestribution({})
    setVisiable(false);
  }
  const onSure = () => {
    // postMethod(`/campaign/account/truncate/voter/${rowData.id}`).then((res) => {
    // })
    let params = {
      account_id: rowData.id,
      [formQuery.voter_key_one]: formQuery.voter_value_one,
      [formQuery.voter_key_two]: formQuery.voter_value_two,
    }
    postMethod('/voter/api/add/voter', params).then((res) => {
      if (res && res.code === 200) {
        successMessage('success')
        setVisiable(false);
        getList(pagination, query);
        setFormQuery({ ...queryParams })
        setDestribution({})
      }
    })
  }
  const onChangeValue = (value, key, type) => {
    setFormQuery({
      ...formQuery,
      [key]: value
    })
  }

  const openAddDonor = (row) => {
    setRowData(row)
    setSelectList([])
    setSelectBListForVal([])
    setVisiable(true);
    // getMethod(`/manage/account/teams/${row.id}`).then((res) => {
    //   if (res && res.code === 200) {
    //     setTeamList(res.data)
    //   }
    // })
  }
  const [destribution, setDestribution] = useState({})

  const getListForVoter = (pagination, val, valP, type) => {
    if (type === 1) {
      // setSelectRVal('')
      setSelectList([])
      setFormQuery({
        "voter_key_one": val,
        "voter_key_two": '',
        "voter_value_one": '',
        "voter_value_two": ''
      })
    } else {
      // setSelectBRVal('')
      setSelectBListForVal([])
      setFormQuery({
        ...formQuery,
        'voter_key_two': val,
        "voter_value_two": ''
      })
    }
    let params = {}
    if (valP) {
      params = {
        [val]: valP
      }
    }
    postMethod(`/voter/api/options/${pagination.pageIndex}/${pagination.pageNum}/${val}`, params).then((res) => {
      if (res && res.code === 200) {
        let list = res.data.list?.map((item) => {
          return {
            label: `${item.option}`,
            value: `${item.option}`,
          }
        })
        if (type === 1) {
          setSelectList(list || [])
        } else {
          setSelectBListForVal(list || [])
        }
      }
    })
  }

  const onPriview = () => {
    let params = {
      [formQuery.voter_key_one]: formQuery.voter_value_one,
      [formQuery.voter_key_two]: formQuery.voter_value_two,
    }
    postMethod('/voter/api/account', { account_zone_condition: JSON.stringify(params) }).then((res) => {
      if (res && res.code === 200) {
        setIsClickDestribution(true)
        setDestribution(res.data)
      }
    })
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box textAlign="right" mb="12px">
        <Text h="32px" display="inline-block" lineHeight="32px">Account name</Text><Input w="180px" ml="12px" value={query.name} onChange={(e) => handleInputChange(e, 'name')} />
        <Text h="32px" display="inline-block" lineHeight="32px" ml="12px">Email</Text><Input w="120px" ml="12px" value={query.email} onChange={(e) => handleInputChange(e, 'email')} />
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          queryList()
        }}>search</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          setVal({
            name: '',
          })
          setPage({
            currentPage: 1,
            pageSize: 10,
          })
          getList({
            currentPage: 1,
            pageSize: 10,
          }, {})
        }}>reset</Button>
        <Button borderRadius={borderRadius} ml="8px" onClick={() => {
          dialogRef.current.setRow({});
        }}>add</Button>
      </Box>

      <SimpleGrid
        mb='0px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card
          mt="12px"
          borderRadius={borderRadius}
          direction='column'
          w='100%'
          px='0px'
          p="0px"
          overflowX={{ sm: "scroll", lg: "scroll" }}>
          <CustomTable
            isChecked={false}
            columns={columns({
              openDialog: (row) => {
                dialogRef.current.setRow(row);
              },
              deleteDialog: (row) => {
                deleteDialogRef.current.delete(row);
              },
              addDialog: (row) => {
                openAddDonor(row)
              },
              truncateDialog: (row) => {
                truncatedeleteDialogRef.current.delete(row);
              }
            })}
            data={dataList}
            emitSelectAll={emitSelectAll}
          />
          <Box mt="32px" mb="30px">
            <Pagination
              paginations={pagination}
              data={dataList}
              total={total}
              onChange={(pagination) => {
                setPage(pagination)
                getList(pagination)
              }}
            ></Pagination>

          </Box>
        </Card>
      </SimpleGrid>
      <Dialog ref={dialogRef} onRefresh={onRefresh}></Dialog>
      <DeleteDialog ref={deleteDialogRef} onDelete={onSureDelete} ></DeleteDialog>
      <TruncateDeleteDialog ref={truncatedeleteDialogRef} onDelete={onSureTruncateDelete} ></TruncateDeleteDialog>

      {/* <AddDialog ref={DonorDataDialogRef}></AddDialog> */}

      <Modal size='xl' isOpen={visiable} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Voter</ModalHeader>
          <ModalCloseButton _focus={{
            boxShadow: "none",
            border: "none"
          }} />
          <ModalBody>
            <Flex>
              <Select className="input-h48" w="150px" placeholder='select' value={formQuery.voter_key_one} onChange={(e) => {
                onChangeValue(e.target.value, 'voter_key_one')
                setSelectBListForVal([])
                if (e.target.value === 'zip_code') {
                  setVoterBList([
                    {
                      value: 'precinct_abbrv',
                      label: 'precinct'
                    }
                  ])
                  // onChangeValue('precinct_abbrv', 'voter_key_two')
                } else if (e.target.value === 'precinct_abbrv') {
                  setVoterBList([
                    {
                      value: 'zip_code',
                      label: 'zip code'
                    },
                  ])
                  // onChangeValue('zip_code', 'voter_key_two')
                }

                getListForVoter({
                  pageIndex: 1,
                  pageNum: 100000000
                }, e.target.value, '', 1)
              }} >
                {
                  voterList?.map((item, index) => {
                    return <option value={item.value} key={index}>{item.label}</option>
                  })
                }
              </Select>
              {
                selectListForVal.length ? 
                <Flex ml="10px">
                  <SelectSearch classN={'voter'} value={formQuery.voter_value_one} data={selectListForVal} onChange={(e) => {
                  onChangeValue(e, 'voter_value_one')
                }}></SelectSearch>
                </Flex>
                 : null
              }
              {/* <Input w="260px" onChange={(e) => onChangeValue(e.target.value, 'voter_value_one')} className="input-h48" focusBorderColor='#3D5A80' placeholder='' /> */}
              {/* <Button onClick={onPriview} type='submit'  >priview</Button> */}
              <Button
                className="input-h48"
                onClick={onPriview}
                borderRadius="6px"
                _hover={{
                  background: 'rgb(40, 58, 83);',
                  border: '2px solid #5BA4FA'
                }}
                _click={{
                  background: 'rgb(40, 58, 83);',
                }}
                background="rgb(40, 58, 83);"
                color="#fff"
                type='submit'
                w='109px'
                ml="10px"
                fontFamily="Inter"
              >
                Preview
                  </Button>
            </Flex>
            {
              formQuery.voter_key_one === 'zip_code' || formQuery.voter_key_one === 'precinct_abbrv' ?
                <Flex mt="10px">
                  <Select className="input-h48" w="150px" placeholder='select' value={formQuery.voter_key_two} onChange={(e) => {
                    onChangeValue(e.target.value, 'voter_key_two')
                    getListForVoter({
                      pageIndex: 1,
                      pageNum: 100000000
                    }, e.target.value, '', 2)
                  }} >
                    {
                      voterBList?.map((item, index) => {
                        return <option value={item.value} key={index}>{item.label}</option>
                      })
                    }

                  </Select>
                  {
                selectListBForVal.length ? 
                <Flex ml="10px">
                <SelectSearch classN={'voter'} value={formQuery.voter_value_two} data={selectListBForVal} onChange={(e) => {
                  onChangeValue(e, 'voter_value_two')
                }}></SelectSearch> </Flex> : null
              }
                  {/* <Input w="260px" onChange={(e) => onChangeValue(e.target.value, 'voter_value_two')} className="input-h48" focusBorderColor='#3D5A80' placeholder='' /> */}
                </Flex> : null
            }
            <div style={{ height: '400px', overflowY: 'scroll', padding: '0 10px' }}>
              {
                destribution.count ? <Flex mt="10px" fontSize="24px" fontWeight="bold">voters: <Box ml="10px">{destribution.count || 0}</Box></Flex> : null
              }

              {
                isClickDestribution && !destribution.count ? <Flex mt="10px" fontSize="24px" justifyContent="center">no voter data</Flex>: null
              }

              {
                destribution.party_cd_dis ?
                  <VoterPerview data={destribution.party_cd_dis} title={'Party'} showVal={true} isKey={false} substr={false}></VoterPerview>
                  : null
              }
              {
                destribution.age_dis ?
                  <VoterPerview data={destribution.age_dis} title={'Age'} showVal={true} isKey={false} substr={false}></VoterPerview>
                  : null
              }
              {
                destribution.gender_dis ?
                  <VoterPerview data={destribution.gender_dis} title={'Gender'} showVal={true} isKey={false} substr={false}></VoterPerview>
                  : null
              }
              {
                destribution.polarity_dist ?
                  <VoterPerview data={destribution.polarity_dist} title={'Polarity'} showVal={true} isKey={false} substr={false}></VoterPerview>
                  : null
              }

              {
                destribution.race_dist ?
                  <VoterPerview data={destribution.race_dist} title={'Race'} showVal={true} isKey={true} substr={false}></VoterPerview>
                  : null
              }
              {
                destribution.out_to_vote_general_dist ?
                  <VoterPerview data={destribution.out_to_vote_general_dist} title={'Voting general'} showVal={true} isKey={false} substr={true}></VoterPerview>
                  : null
              }
              {
                destribution.out_to_vote_primary_dist ?
                  <VoterPerview data={destribution.out_to_vote_primary_dist} title={'Voting primary'} showVal={true} isKey={false} substr={true}></VoterPerview>
                  : null
              }
              {
                destribution.out_to_vote_municipal_dist ?
                  <VoterPerview data={destribution.out_to_vote_municipal_dist} title={'Voting municipal'} showVal={true} isKey={false} substr={true}></VoterPerview>
                  : null
              }
            </div>
          </ModalBody>
          <ModalFooter>

            <Button
              type="button"
              _hover={{
                background: '#fff',
                border: '2px solid #5BA4FA'
              }}
              background="#fff"
              color="rgb(40, 58, 83)"
              border="1px solid rgb(40, 58, 83)"
              borderRadius="6px"
              w='109px'
              marginRight="24px"
              onClick={onClose}
              fontFamily="Inter"
            >
              Cancel
            </Button>
            <Button
              borderRadius="6px"
              _hover={{
                background: 'rgb(40, 58, 83);',
                border: '2px solid #5BA4FA'
              }}
              _click={{
                background: 'rgb(40, 58, 83);',
              }}
              background="rgb(40, 58, 83);"
              color="#fff"
              // type='submit'
              w='109px'
              fontFamily="Inter"
              onClick={onSure}
            >
              Add Voter
            </Button>
            {/* <Button onClick={onClose} colorScheme='blue' mr={3}>
              Close
            </Button>
            <Button onClick={onSure} type='submit' colorScheme='teal' >Add Voter</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
