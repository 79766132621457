import React from 'react'

import { Icon } from '@chakra-ui/react'
import {
  MdBarChart,
  // MdPerson,
  MdHome,
  MdLock,
  // MdOutlineShoppingCart,
} from 'react-icons/md'

// Admin Imports
// import MainDashboard from "views/admin/default";
import AdminUserManagement from 'views/admin/user-management'
import AccountManagement from 'views/admin/account/account-management'
import AccountCampaignManagement from 'views/admin/account-campaign/account-management'
import AccountCanvassManagement from 'views/admin/account-canvass/account-management'
import SubscribeCampaignCanvassManagement from 'views/admin/account-canvass/subscribe-campaign'

import SubscribeCampaignManagement from 'views/admin/notices/subscribe-campaign'

import SubscribeManagement from 'views/admin/notices/subscribe-management'
import ServicePlanChangeManagement from 'views/admin/notices/service-plan-changes-management'
import CommitteeNotInDatabaseManagement from 'views/admin/notices/committee-not-in-database'

// import AccountPlanManagement from "views/admin/account/account-plan";

import MlModel from 'views/admin/metadata/ml-model'
import Donor from 'views/admin/metadata/donor'
import Committee from 'views/admin/metadata/committee'
import Database from 'views/admin/database-management/database'

import UserManagement from 'views/polymo/setting'

import PolymofundLearnMore from 'views/polymo/polymofund/learn-more'
import PolymofundCreatBook from 'views/polymo/polymofund/create-book'

import PolymofundSwitchCampaign from 'views/polymo/polymofund/switch-campaign'
import PolymofundSwitchCampaignMobile from 'views/polymo/polymofund/switch-campaign-mobile'

import PolymoMobileHome from 'views/polymo/mobile/home'
import PolymoMobileLearnMore from 'views/polymo/mobile/learn-more'
import PolymoMobileDownload from 'views/polymo/mobile/download'

import PolymofundViewTranscationCampaign from 'views/polymo/polymofund/view-transcation'

import PolymofundCheckTeam from 'views/polymo/polymofund/check-team'
import PolymofundAllTeam from 'views/polymo/polymofund/all-teams'

import PolymofundAccountManagement from 'views/polymo/polymofund/account-management'
import PolymofundContactUs from 'views/polymo/polymofund/contact-us'
import PolymofundServicePlan from 'views/polymo/polymofund/service-plan'
import PolymofundPayment from 'views/polymo/polymofund/payment'
import PolymofundSmsPayment from 'views/polymo/polymofund/sms-payment'
import PolymofundDisclaimers from 'views/polymo/polymofund/disclaimers'
import PolymofundAbout from 'views/polymo/polymofund/about'
import PolymofundDownload from 'views/polymo/polymofund/download'

// import TeamUserManagement from "views/polymo/team/team-management";
// import Private from "views/polymo/fundraising/private";
// import Public from "views/polymo/fundraising/public";
// import WaitContact from "views/polymo/fundraising/wait-contact";

// import Republican from "views/polymo/report/republican";
// import Democratic from "views/polymo/report/democratic";
// import Generate from "views/polymo/report/generate-report";

// import RTL from "views/admin/rtl";
import MainHome from 'views/polymo/home'

// Auth Imports
import SignInCentered from 'views/auth/signIn'

import SignInUserCentered from 'views/auth/signInUser'
import SignUpUserCentered from 'views/auth/signUpUser'
import forgetPasswordCentered from 'views/auth/forgetPassword'

const routes = [
  // CMS路由
  // {
  //   name: "Dashboard",
  //   layout: "/cms",
  //   key: 1,
  //   path: "/dashboard",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard
  // },
  {
    name: 'User Management',
    key: 4,
    category: true,
    path: '/user',
    showNav: true,
    bt: 'public',
    items: [
      {
        name: 'User',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/user-management',
        component: AdminUserManagement,
        accordion: true,
        showNav: true,
      },
    ],
  },
  {
    name: 'PolumoFund Account',
    key: 2,
    path: '/account-cms',
    category: true,
    bt: 'polymo',
    items: [
      {
        name: 'Account Management',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/account-cms-management',
        component: AccountManagement,
        accordion: true,
      },
    ],
  },
  {
    name: 'PolymoTeams Account',
    key: 2,
    path: '/account-campaign-cms',
    category: true,
    bt: 'campaign',
    items: [
      {
        name: 'Account',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/account-campaign-cms-management',
        component: AccountCampaignManagement,
        accordion: true,
      },
    ],
  },
  {
    name: 'PolymoTeams Subscriptions',
    key: 2,
    path: '/subscription-campaign-cms',
    category: true,
    bt: 'campaign',
    items: [
      {
        name: 'Subscription',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/subscription-campaign-cms-management',
        component: SubscribeCampaignManagement,
        accordion: true,
      },
    ],
  },
  {
    name: 'PolymoCampaign Account',
    key: 2,
    path: '/account-canvass-cms',
    category: true,
    bt: 'canvass',
    items: [
      {
        name: 'Account',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/account-canvass-cms-management',
        component: AccountCanvassManagement,
        accordion: true,
      },
    ],
  },

  {
    name: 'PolymoCampaign Subscriptions',
    key: 2,
    path: '/subscription-campaign-canvass-cms',
    category: true,
    bt: 'canvass',
    items: [
      {
        name: 'Subscription',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/subscription-campaign-canvass-management',
        component: SubscribeCampaignCanvassManagement,
        accordion: true,
      },
    ],
  },
  {
    name: 'Metadata Management',
    key: 4,
    category: true,
    path: '/metadata',
    bt: 'polymo',
    items: [
      {
        name: 'ML Model',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/metadata-mlmodel',
        component: MlModel,
        accordion: true,
      },
      {
        name: 'Donor',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/metadata-donor',
        component: Donor,
        accordion: true,
      },
      // {
      //   name: "Committee",
      //   layout: "/cms",
      //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
      //   path: "/metadata-committee",
      //   component: Committee,
      //   accordion: true
      // },
    ],
  },
  {
    name: 'Notices',
    key: 2,
    path: '/notices',
    category: true,
    bt: 'polymo',
    items: [
      {
        name: 'Subscriptions',
        layout: '/cms',
        key: 2,
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/notices-subscribe-management',
        component: SubscribeManagement,
        accordion: true,
      },
      {
        name: 'ServicePlan Changes',
        layout: '/cms',
        key: 2,
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/notices-service-plan-change',
        component: ServicePlanChangeManagement,
        accordion: true,
      },
      {
        name: 'Committee not in Database',
        layout: '/cms',
        key: 2,
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/committee-not-in-database',
        component: CommitteeNotInDatabaseManagement,
        accordion: true,
      },
    ],
  },
  {
    name: 'database Management',
    key: 2,
    category: true,
    path: '/database',
    bt: 'polymo',
    showNav: true,
    items: [
      {
        name: 'data import',
        layout: '/cms',
        icon: (
          <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />
        ),
        path: '/database-management',
        component: Database,
        accordion: true,
        showNav: true,
      },
    ],
  },

  // APP路由
  // APP登录
  {
    name: 'Sign In',
    layout: '/polymo',
    path: '/sign-in',
    key: 11,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInUserCentered,
    commonRoute: true,
  },
  // APP注册
  {
    name: 'Sign Up',
    layout: '/polymo',
    path: '/sign-up',
    key: 11,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpUserCentered,
    commonRoute: true,
  },
  // APP忘记密码
  {
    name: 'Forgot Password',
    layout: '/polymo',
    path: '/forget-password',
    key: 11,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: forgetPasswordCentered,
    commonRoute: true,
  },
  {
    name: 'Home',
    layout: '/polymo',
    path: '/home',
    key: 11,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainHome,
    showProduct: true,
    // showProduct: true,
  },
  {
    name: 'Setting',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/setting',
    component: UserManagement,
    accordion: true,
    showProduct: true,
  },
  {
    name: 'LearnMore',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/learn-more',
    component: PolymofundLearnMore,
    accordion: true,
    showProduct: true,
    showPricing: true,
  },

  {
    name: 'SwitchCampaign',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/switch-campaign',
    component: PolymofundSwitchCampaign,
    accordion: true,
    showNav: true,
  },
  {
    name: 'SwitchCampaignMobile',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/switch-campaign-mobile',
    component: PolymofundSwitchCampaignMobile,
    accordion: true,
    showNav: true,
  },
  {
    name: 'PolymoMobileHome',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/mobile-home',
    component: PolymoMobileHome,
    accordion: true,
    showNav: true,
  },
  {
    name: 'PolymoMobileLearnMore',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/mobile-learnmore',
    component: PolymoMobileLearnMore,
    accordion: true,
    showNav: true,
  },
  {
    name: 'PolymoMobileDownload',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/Mobile-download',
    component: PolymoMobileDownload,
    accordion: true,
    showNav: true,
  },
  {
    name: 'ViewTranscation',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/view-transcation',
    component: PolymofundViewTranscationCampaign,
    accordion: true,
    showNav: true,
  },
  {
    name: 'AccountManagement',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/account-management',
    component: PolymofundAccountManagement,
    accordion: true,
    showNav: true,
  },
  {
    name: 'CheckTeam',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/check-team',
    component: PolymofundCheckTeam,
    accordion: true,
    showNav: true,
  },
  {
    name: 'AllTeams',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/all-teams',
    component: PolymofundAllTeam,
    accordion: true,
    showNav: true,
  },
  {
    name: 'ContactUs',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/contact-us',
    component: PolymofundContactUs,
    accordion: true,
    showProduct: true,
  },
  {
    name: 'Pricing',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/pricing',
    component: PolymofundServicePlan,
    accordion: true,
    showPricing: true,
    showProduct: true,
  },
  {
    name: 'Payment',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/payment',
    component: PolymofundPayment,
    accordion: true,
    showNav: true,
  },
  {
    name: 'SmsPayment',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/sms-payment',
    component: PolymofundSmsPayment,
    accordion: true,
    showNav: true,
  },
  {
    name: 'CreateBook',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/create-book',
    component: PolymofundCreatBook,
    accordion: true,
    showNav: true,
  },
  {
    name: 'disclaimers',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/disclaimers',
    component: PolymofundDisclaimers,
    accordion: true,
    showProduct: true,
  },
  {
    name: 'privacy',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/privacy',
    component: PolymofundAbout,
    accordion: true,
    showProduct: true,
  },
  {
    name: 'Download',
    layout: '/polymo',
    key: 11,
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/download',
    component: PolymofundDownload,
    accordion: true,
    showProduct: true,
  },

  // {
  //   name: "Campagin Team",
  //   key: 11,
  //   layout: "/polymo",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/team-management",
  //   component: TeamUserManagement,
  //   accordion: true
  // },
  // {
  //   name: "Fundraising Event",
  //   key: 15,
  //   category: true,
  //   path: "/fundraising",
  //   items: [
  //     {
  //       name: "Private Donor Data",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/fundraising-private",
  //       component: Private,
  //       accordion: true
  //     },
  //     {
  //       name: "Public Donor Data",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/fundraising-public",
  //       component: Public,
  //       accordion: true
  //     },
  //     {
  //       name: "Contact Donor Data",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/fundraising-wait-cantact",
  //       component: WaitContact,
  //       accordion: true
  //     },
  //   ]
  // },
  // {
  //   name: "Report Management",
  //   key: 16,
  //   category: true,
  //   path: "/report",
  //   items: [
  //     {
  //       name: "Republican",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/report-republican",
  //       component: Republican,
  //       accordion: true
  //     },
  //     {
  //       name: "Democratic",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/report-democratic",
  //       component: Democratic,
  //       accordion: true
  //     },
  //     {
  //       name: "Generate",
  //       layout: "/polymo",
  //       icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //       path: "/report-generate",
  //       component: Generate,
  //       accordion: true
  //     },
  //   ]
  // },

  // CMS登录
  {
    name: 'Sign In',
    layout: '/cms',
    // key: 1,
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
]

export default routes
